<template>
  <front-layout>
    <div>
      Wait before redirect
    </div>
  </front-layout>
</template>

<script>
import FrontLayout from "../../layouts/FrontLayout"

export default {
  components: {
    FrontLayout,
  },
  data: function () {
    return {

    }
  },
  mounted() {
    //
  },
  methods: {
    //
  },
}
</script>
